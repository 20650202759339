import { useMemo } from 'react';
import { useRouter } from 'next/router';
import Redirect from 'helpers/redirect';
import { useFeatureFlags, FEATURE_FLAG_LIST } from 'hooks';
import Login, { LoginProps } from 'ui/authentication/login';
import { useAccount } from 'frontastic/hooks';

type Props = {
  data: LoginProps;
};

export enum SUCCESS_MESSAGE_TYPE {
  CHANGE_PASSWORD_NOTIFICATION = 'cpn',
}

const AccountLoginTastic = ({ data }: Props) => {
  const router = useRouter();
  const { isFeatureEnabled } = useFeatureFlags();

  if (router.query.cpn) {
    data.successMessage = { type: SUCCESS_MESSAGE_TYPE.CHANGE_PASSWORD_NOTIFICATION };
  }

  const { loggedIn } = useAccount();

  const isLVPChangesActive = isFeatureEnabled(FEATURE_FLAG_LIST.LVP_CHANGES);

  const redirectLink = useMemo(() => {
    const lvp = router?.query?.lvp ? router.query.lvp.toString() : '';
    return lvp && isLVPChangesActive ? `/${lvp}` : `/`;
  }, [router.query.lvp, isLVPChangesActive]);

  if (loggedIn) return <Redirect target={redirectLink} />;

  return <Login {...data} />;
};

export default AccountLoginTastic;
