import { useEffect, useState } from 'react';
import NextLink from 'next/link';
import {
  Notification,
  Link,
  Button,
  Heading,
  Field,
  Icon,
  yogoRedIcon,
  LoadingIndicator,
} from '@lululemon/ecom-pattern-library';
import { useFormik } from 'formik';
import { useFormat } from 'helpers/hooks/useFormat';
import useValidate from 'helpers/hooks/useValidate';
import { AUTH } from 'helpers/utils/googleAnalyticsEvents';
import { useAnalytics } from 'hooks/useAnalytics';
import { useAccount } from 'frontastic';
import { SUCCESS_MESSAGE_TYPE } from 'frontastic/tastics/account/login';
import styles from './login.module.scss';

type Props = {
  onLogin?: () => void;
  errorMessages: {
    api: string;
    emailRequired: string;
    passwordRequired: string;
    emailInvalid: string;
    accountLocked: string;
    inactiveAccountMessage: string;
    inactiveBusinessUnitMessage: string;
  };
  successMessage?: { type: SUCCESS_MESSAGE_TYPE; message?: string };
};

type LoginFormValues = {
  email: string;
  password: string;
  rememberMe: boolean;
};

const LoginForm = ({ onLogin, errorMessages, successMessage }: Props) => {
  //i18n messages
  const { formatMessage: formatErrorMessage } = useFormat({ name: 'error' });
  const { formatMessage: formatAccountMessage } = useFormat({ name: 'account' });
  const { validateEmail } = useValidate();
  //account actions
  const { login } = useAccount();
  //Google Analytics
  const { trackEvent, EVENT_CATEGORY } = useAnalytics();

  const { accountLocked, inactiveAccountMessage, inactiveBusinessUnitMessage } = errorMessages;

  //error
  const [error, setError] = useState('');

  //success
  const [success, setSuccess] = useState('');

  //processing...
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    trackEvent(EVENT_CATEGORY.PAGE_VIEW, {});
    if (successMessage && successMessage.type) {
      if (successMessage.message) {
        setSuccess(successMessage.message);
      } else if (successMessage.type == SUCCESS_MESSAGE_TYPE.CHANGE_PASSWORD_NOTIFICATION) {
        const message = formatAccountMessage({
          id: 'password.reset.success.message',
          defaultMessage: 'Your password has been updated. Please sign in below to continue.',
        });
        setSuccess(message);
      }
    }
  }, []);

  const resetFeedback = () => {
    setError('');
    setSuccess('');
  };

  const validate = async (values: LoginFormValues) => {
    const errors: any = {};

    if (!values.email) {
      errors.email = errorMessages.emailRequired;
    } else if (!validateEmail(values.email)) {
      errors.email = errorMessages.emailInvalid;
    }

    if (!values.password) {
      errors.password = errorMessages.passwordRequired;
    }

    return errors;
  };

  // incorrect details message
  const authWrongMessage =
    errorMessages?.api || formatErrorMessage({ id: 'auth.wrong', defaultMessage: 'Wrong email address or password' });

  //login user
  const loginUser = async () => {
    setLoading(true);
    setSuccess('');
    try {
      const { res, error } = await login(formik.values.email, formik.values.password, formik.values.rememberMe);

      if (res?.id) {
        onLogin?.();
        setLoading(false);
      } else {
        let errorMsg = '';
        if (error?.message === 'Error: Blocked') {
          errorMsg = accountLocked; // account is locked, due to multiple wrong password attempt; message
          trackEvent(EVENT_CATEGORY.COMPONENT_EVENT, AUTH.ACCOUNT_LOCKOUT);
          trackEvent(EVENT_CATEGORY.ERROR, AUTH.ACCOUNT_LOCKOUT_ERROR);
        } else if (error?.message === 'Error: account_inactive') {
          errorMsg = inactiveAccountMessage;
        } else if (error?.message === 'Error: account_to_business_unit_association_not_found') {
          errorMsg = inactiveAccountMessage;
        } else if (error?.message === 'Error: business_unit_inactive') {
          errorMsg = inactiveBusinessUnitMessage;
        } else {
          errorMsg = authWrongMessage;
        }
        setError(errorMsg);
        trackEvent(EVENT_CATEGORY.ERROR, AUTH.LOGIN_API_ERROR());
        setLoading(false);
        return;
      }
      trackEvent(EVENT_CATEGORY.COMPONENT_EVENT, AUTH.SIGN_IN_INITIATED);
    } catch (error) {
      setError(formatErrorMessage({ id: 'wentWrong', defaultMessage: 'Sorry. Something went wrong..' }));
      setSuccess('');
      setLoading(false);
      trackEvent(EVENT_CATEGORY.ERROR, AUTH.LOGIN_API_ERROR());
    }
    trackEvent(EVENT_CATEGORY.APP_RESPONSE, AUTH.LOGIN_API_RESPONSE);
  };

  //form submission
  const handleSubmit = async (values: LoginFormValues) => {
    //processing starts

    // removes previous error message if any
    setError('');
    setSuccess('');
    const emailValid = validateEmail(values.email);

    if (!values.password || !values.email || !emailValid) {
      return;
    }

    loginUser();
    resetFeedback();
  };

  const formik = useFormik({
    initialValues: {
      email: '',
      password: '',
      rememberMe: false,
    },
    validate,
    validateOnMount: true,
    onSubmit: handleSubmit,
  });

  const forgotPasswordLinkHandler = () => {
    trackEvent(EVENT_CATEGORY.COMPONENT_EVENT, AUTH.FORGOT_PASSWORD_LINK);
  };

  const feedback = error || success;

  return (
    <>
      {loading && (
        <LoadingIndicator
          color={'red'}
          className={styles.loadingIndicator}
          data-testid={'login-form__loading-indicator_test-id'}
        />
      )}
      <div className="mb-36 md:mb-56 lg:mb-84">
        <div className="m-auto grid max-w-[540px] px-20">
          <div className="bg-white p-36">
            <Icon content={yogoRedIcon} className="mb-25 size-54" />

            <Heading className="lll-text-xsmall" tag="h1" showLineAccent data-testid="login-page__heading_test-id">
              Welcome to the Strategic Sales Site
            </Heading>

            <form onSubmit={formik.handleSubmit} id="submit-login" className="py-20">
              <Notification
                data-testid="login-page__notification_test-id"
                visible={!!feedback.length}
                type={!!error.length ? 'error' : 'success'}
              >
                {feedback}
              </Notification>

              <Field
                id="email"
                type="email"
                name="email"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.email}
                hint={formik.touched.email && formik.errors.email != undefined ? formik.errors.email : undefined}
                status={formik.touched.email && formik.errors.email != undefined ? 'error' : undefined}
                data-testid={'login-page__email-input_test-id'}
              >
                Email address
              </Field>
              <Field
                id="password"
                className={'mt-8'}
                name="password"
                type="password"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                allowShowPassword
                hint={
                  formik.touched.password && formik.errors.password != undefined ? formik.errors.password : undefined
                }
                status={formik.touched.password && formik.errors.password != undefined ? 'error' : undefined}
                data-testid={'login-page__password-input_test-id'}
              >
                Password
              </Field>

              <div className="my-16 flex items-center justify-between p-0">
                <Link
                  className={styles.forgotLink}
                  variant="underline"
                  data-testid="login-page__forgot-your-password-link_test-id"
                  tag="div"
                >
                  <NextLink href={'/forgot-password'}>
                    <a onClick={forgotPasswordLinkHandler}>
                      {formatAccountMessage({ id: 'password.forgot', defaultMessage: 'Forgot your password?' })}
                    </a>
                  </NextLink>
                </Link>
              </div>

              <Button
                block
                kind="primary"
                type="submit"
                disabled={!formik.isValid}
                data-testid="login-page__sign-in-button_test-id"
              >
                {formatAccountMessage({ id: 'sign.in', defaultMessage: 'Sign in' })}
              </Button>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default LoginForm;
