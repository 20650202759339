import { Reference } from 'types/reference';
import { SUCCESS_MESSAGE_TYPE } from 'frontastic/tastics/account/login';
import LoginForm from './login-form';

export type LoginProps = {
  signInLink: Reference;
  accountLink?: Reference;
  onLogin?: () => void;
  errorMessages?: string;
  incorrectDetailsMessage: string;
  inactiveAccountMessage: string;
  inactiveBusinessUnitMessage: string;
  emailRequired: string;
  passwordRequired: string;
  emailInvalid: string;
  accountLocked: string;
  successMessage?: { type: SUCCESS_MESSAGE_TYPE; message?: string };
};

const Login = ({
  onLogin,
  incorrectDetailsMessage,
  inactiveAccountMessage,
  inactiveBusinessUnitMessage,
  emailRequired,
  passwordRequired,
  emailInvalid,
  accountLocked,
  successMessage,
}: LoginProps) => {
  return (
    <LoginForm
      onLogin={onLogin}
      errorMessages={{
        api: incorrectDetailsMessage,
        inactiveAccountMessage,
        inactiveBusinessUnitMessage,
        emailRequired,
        passwordRequired,
        emailInvalid,
        accountLocked,
      }}
      successMessage={successMessage}
    />
  );
};

export default Login;
