import { useEffect } from 'react';
import { useRouter } from 'next/router';
import { Reference } from 'types/reference';
import { resolveReferenceTarget } from './reference';

type Props = {
  target?: string | Reference;
};

const Redirect = ({ target }: Props) => {
  const router = useRouter();

  useEffect(() => {
    if (!target) return;
    const resolvedTarget = typeof target === 'string' ? target : (resolveReferenceTarget(target) as string);
    router.push(resolvedTarget);
  }, [target, router]);

  return <></>;
};

export default Redirect;
